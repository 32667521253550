<template>
  <v-card elevation="0" cols="12">
    <v-card-actions cols="12">
      <v-row>
        <v-col> {{ file.file.name }} ({{ filesize }}) </v-col>
        <v-col v-if="canBeRemoved" class="text-right">
          <v-icon medium @click="onRemove">
            mdi-close
          </v-icon>
        </v-col>
        <v-col v-if="uploading" class="text-right">
          <v-progress-circular
            :size="20"
            color="accent"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-col v-if="isDone" class="text-right">
          <v-icon medium>
            mdi-check
          </v-icon>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-row v-if="isError">
      <v-col cols="12">
        <span class="error err">{{ error }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { filesize } from '@/helpers/filesize';
export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {};
  },
  computed: {
    filesize() {
      return filesize(this.file.file.size);
    },
    canBeRemoved() {
      return this.status == 'new';
    },
    uploading() {
      return this.status == 'uploading';
    },
    isDone() {
      return this.status == 'done';
    },
    isError() {
      return this.status.startsWith('error:');
    },
    error() {
      if (!this.isError) {
        return '';
      }
      console.log('error:', this.status);
      return this.status.substring('error:'.length);
    }
  },
  methods: {
    onRemove() {
      this.$emit('remove', this.file);
    }
  }
};
</script>

<style lang="sass" scoped>
.err
    font-size: 1em
    margin: 30px
.col
    padding: 0px
    margin-bottom: 10px
    font-weight: bold
</style>
